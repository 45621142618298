<template>
  <section id="posts">
    <base-card class="px-4">
      <template v-for="(post, i) in posts">
        <v-divider
          v-if="i !== 0"
          :key="`divider-${i}`"
          class="mb-5"
        />
        <post
          :key="i"
          :value="post"
          dense
        />

        <div
          :key="`more-${i}`"
          class="mb-5"
        >
          <v-btn
            :to="`/posts/${post.slug}`"
            class="font-weight-light ma-0"
            color="accent"
          >
            Read More
          </v-btn>
        </div>
      </template>
    </base-card>
  </section>
</template>

<script>
  // Utilities
  import {
    mapState
  } from 'vuex'

  export default {
    components: {
      Post: () => import('@/components/Post')
    },

    computed: {
      ...mapState('content', ['posts'])
    }
  }
</script>
